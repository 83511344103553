<template>
  <v-main :anchor-layout="layoutName" v-if="isLogin">
    <v-navigation-drawer
      app clipped dark
      class="d-print-none"
      :style="{ zIndex: $zIndexConfig.sidebar }"
      v-model="showSidebar"
    >
      <sidebar
        v-if="showSidebar"
      ></sidebar>
    </v-navigation-drawer>

    <v-app-bar eagle-header app fixed clipped-left dark class="d-print-none">
      <v-app-bar-nav-icon @click.stop="toggleSidebar"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <v-toolbar-title class="d-flex pa-2 align-center">
        <!-- logo -->
        <v-img
          width="25"
          height="25"
          :src="siteLogoUrl"
        ></v-img>
        <span class="pa-2">{{siteName}}</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <breadcrumb scope="default"></breadcrumb>

    <v-container fluid eagle-main-container>
      <router-view></router-view>
    </v-container>

    <appFooter></appFooter>
  </v-main>
</template>

<script lang="babel" type="text/babel">
import layoutMixins from '@/layout/layoutMixins'
export default {
  mixins: [layoutMixins],
  data: () => ({
    layoutName: 'admin',
    showSidebar: false,
  }),
  computed: {
    siteLogoUrl() {
      return this.$store.getters[`base/siteBlackLogoUrl`]
    },
  },
  created() {
    this.showSidebar = window.helper.isMobile() == false
  },
  mounted() {
    this.checkIso27001DefaultPassword()
  },
  methods: {
    toggleSidebar() {
      this.showSidebar = !this.showSidebar
    },
    redirect() {
      this.$router.replace({
        name: 'login',
        query: { path: this.$route.fullPath }
      })
    },
  },
  watch: {
    isLogin: {
      immediate: true,
      handler() {
        if(!this.isLogin) this.redirect()
      },
    },
  },
  components: {
    sidebar: () => import('layout/admin/sidebar/sidebar.vue'),
    appFooter: () => import('layout/admin/footer.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
.eagle-main-container, *[eagle-main-container]
  min-height: 85vh
</style>
