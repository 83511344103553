import Vue from 'vue'
import {mapGetters} from 'vuex'

export default Vue.extend({
  data: () => ({
    checkIso27001DefaultPasswordInterval: null,
    popupKey: 'reset-default-password-warning',
  }),
  methods: {
    setupLayout() {
      this.$store.dispatch('base/setupLayoutName', this.layoutName)
    },
    async checkIso27001DefaultPassword() {
      if(!this.iso27001Feature) return
      if(!this.isLogin) return
      if(!this.showChangeDefaultPasswordWarning) return
      await this.$helper.delay(0.7)
      this.checkIso27001DefaultPasswordKernel()
      window.clearInterval(this.checkIso27001DefaultPasswordInterval)
      this.checkIso27001DefaultPasswordInterval = null
      this.checkIso27001DefaultPasswordInterval = window.setInterval(() => {
        this.checkIso27001DefaultPasswordKernel()
      }, 10000)
    },
    async checkIso27001DefaultPasswordKernel() {
      if(this.isResetPasswordPage) return
      if(!this.showChangeDefaultPasswordWarning) {
        window.clearInterval(this.checkIso27001DefaultPasswordInterval)
        return
      }
      this.$apopup.base({
        key: this.popupKey,
        title: this.$t(`action.reset-default-password`),
        content: this.$t(`action.reset-default-password.help`),
        close: false,
        persistent: true,
        applyCallback: () => {
          this.$router.push({
            name: 'reset-password',
            query: { path: this.$route.fullPath }
          })
        },
      })
    },
  },
  computed: {
    isResetPasswordPage() {
      return this.$route.name === 'reset-password'
    },
    iso27001Feature() {
      return this.$eagleLodash.get(this.feature, 'iso27001')
    },
    showChangeDefaultPasswordWarning() {
      return this.$eagleLodash.get(this.tokenData, 'has_change_default_password') === false
    },
    ...mapGetters({
      layoutReady: 'base/layoutReady',
      siteLogoUrl: 'base/siteLogoUrl',
      siteName: 'base/siteName',
      isLogin: 'token/isLogin',
      tokenData: 'token/data',
      feature: 'base/feature',
    }),
  },
})
